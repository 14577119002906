export default [
  'all',
  'invoices.id',
  'invoices.status',
  'invoices.teamId',
  'invoices.provider',
  'invoices.days',
  'invoices.usersLimit',
  'invoices.plan',
  'invoices.browserProfilesLimit',
  'invoices.sum',
  'invoices.fee',
  'invoices.coin',
  'invoices.upgrade',
  'invoices.currency',
  'invoices.exchange',
  'invoices.promoId',
  'invoices.merchantId',
  'invoices.created_at',
  'invoices.updated_at',
  'invoices.deleted_at',
  'invoices.payUrlCreatedDate',
  'invoices.payUrl',
  'invoices.recurringPaymentId',
  'invoices.recurringPlanId',
  'invoices.unlimintPaymentId',
  'invoices.barcode',
  'invoices.pdfUrl',
  'invoices.typableLine',
  'invoices.dueDate',
  'invoices.dolphinPayId',
  'invoices.reason',
  'invoices.comment',
  'payment_statements.owner_username',
  'payment_statements.country_by_ip',
  'payment_statements.ip',
  'payment_statements.eu',
  'payment_statements.eu_by_ip',
  'payment_statements.fee_percent',
  'payment_statements.fee_usd_amount',
  'payment_statements.fee_country_usd_amount',
  'payment_statements.fee_country_percent',
  'payment_statements.discount_percent',
  'payment_statements.amount',
  'payment_statements.base_price',
  'payment_statements.remaining_fare',
  'payment_statements.payment_method',
  'payment_statements.company_name',
  'payment_statements.country',
  'payment_statements.address',
  'payment_statements.tax_id',
  'payment_statements.postal_code',
  'payment_statements.address_details',
  'payment_statements.city',
  'payment_statements.state_region',
  'payment_statements.purchase_order',
];
